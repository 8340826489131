<template>
  <q-card flat outline class="bg-lmsBackground">
    <q-card-section class="row justify-between items-center bg-lmsSidebar">
      <div class="text-h4">Links</div>
      <div class="row justify-end">
        <q-btn :disable="links[linksIndex - 6] ? false : true" flat round icon="keyboard_arrow_left" @click="linksIndex -= 6" />
        <q-btn :disable="links[linksIndex + 6] ? false : true" flat round icon="keyboard_arrow_right" @click="linksIndex += 6" />
      </div>
    </q-card-section>
    <!-- <q-separator inset/> -->
    <q-card-section class="list-background-custom q-mb-sm">
      <div class="row q-gutter-md">
        <div v-for="link in links.slice(linksIndex, linksIndex + 6)" :key="link.uid">
          <q-card class="cursor-pointer bg-lmsBackground" style="min-width: 200px" flat>
            <q-card-section class="shadow-2 relative-position q-mb-sm q-mt-sm">
              <q-img @click="doLink(link.link)" :src="link.path" :alt="link.path" basic class="cursor-pointer">
                <div v-if="link.settings && link.settings.overlayTitle && link.settings.title" class="absolute-full text-subtitle1 text-weight-medium flex flex-center">
                  {{ link.settings.title }}
                </div>
                <q-tooltip v-if="link.settings.title">{{ link.settings.title }}</q-tooltip>
              </q-img>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  data () {
    return {
      linksIndex: 0
    }
  },
  computed: {
    links: function () {
      return this.$store.getters['links/getByAssignedUid'](this.$store.getters['auth/filterBy'])
    }
  },
  methods: {
    doLink (link) {
      let linkParts = link.split('>')
      switch (linkParts[0]) {
        case 'page':
          this.$router.push(linkParts[1])
          break
        case 'module':
          this.$router.push({ name: 'module', params: { moduleId: linkParts[1] } }).catch(() => {})
          break
        case 'custom':
          window.open(linkParts[1], '_blank')
          break
      }
    }
  }
}
</script>
