<template>
  <div class="container-layout-custom" style="max-width: 1980px;">
    <q-resize-observer />
    <div class="q-gutter-sm padding-bottom-15 q-py-md q-px-xl">

      <div class="row full-width">

        <!-- filter dropdown -->
        <div v-if="$store.getters['auth/homePageFilter']" class="col-12 q-mb-md">
          <div class="col-12 float-right">
            <MediaSelect v-bind:selection.sync="filterItemsBy" label="Showing Homepage for:" mode="view" />
          </div>
        </div>

        <!-- banners -->
        <div v-if="banners.length > 0" class="col-12 row full-width justify-center q-mb-md">
          <q-carousel animated :autoplay="10000" infinite :arrows="banners.length > 1" v-model="bannerSlide" transition-prev="slide-right" transition-next="slide-left" class="full-width">
            <q-carousel-slide v-for="(banner, index) in banners" :key="banner.uid" :name="index" :img-src="banner.path" />
          </q-carousel>
        </div>

        <!-- below banners -->
        <div class="col-12 row items-stretch">
          <hp-layout-one :news="newsItems" v-if="news.length > 0" />
          <hp-layout-two v-else />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import hpLayoutOne from 'components/homepage/hpLayoutOne'
import hpLayoutTwo from 'components/homepage/hpLayoutTwo'
import MediaSelect from 'components/MediaSelect'
import { date } from 'quasar'

export default {
  name: 'homePage',
  components: { hpLayoutOne, hpLayoutTwo, MediaSelect },
  data () {
    return {
      newsItems: [],
      bannerSlide: 0,
      filterByOptions: ['Company', 'Location', 'Department']
    }
  },
  computed: {
    assignOptions () {
      return [
        { label: 'Company', id: 'Company' },
        { label: 'Location', id: this.$store.getters['auth/customer'] },
        { label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] }
      ]
    },
    filterItemsBy: {
      get: function () {
        return this.$store.getters['auth/filterBy']
      },
      set: function (newValue) {
        this.$store.dispatch('auth/setFilterBy', newValue)
      }
    },
    banners: function () {
      return this.$store.getters['banners/getByAssignedUid'](this.filterItemsBy)
    },
    news: function () {
      let allNews = this.$store.getters['news/getByAssignedUid'](this.newsItems, this.filterItemsBy)
      let today = new Date()
      return allNews.filter(news => {
        let publishDiff = date.getDateDiff(new Date(news.publish_at), today, 'seconds')
        let expirationDiff = date.getDateDiff(new Date(news.expires_at), today, 'seconds')
        return (publishDiff < 0) && (expirationDiff > 0 || news.expires_at === null)
      })
    }
  },
  created () {
    this.$q.loading.show({})
    this.$store.dispatch('news/fetch').then((response) => {
      this.newsItems = response
      this.$q.loading.hide()
    })
  }
}
</script>

<style lang="stylus">
.q-carousel-track > .banner-slider-styles
  padding-left 0px !important
  padding-right 0px !important
.q-card > custom-media-card
  border none !important
</style>
