<template>
  <q-card flat class="bg-lmsBackground">
    <q-card-section class="row justify-between items-center bg-lmsSidebar" id="uploadSection">
      <div class="text-h4">Documents</div>
      <div class="row justify-end">
        <q-btn :disable="publishedUploads[uploadsIndex - 4] ? false : true" flat round icon="keyboard_arrow_left" @click="uploadsIndex -= 4" />
        <q-btn :disable="publishedUploads[uploadsIndex + 4] ? false : true" flat round icon="keyboard_arrow_right" @click="uploadsIndex += 4" />
      </div>
    </q-card-section>
    <!-- <q-separator inset/> -->
    <q-card-section>
      <q-list no-border link highlight>
          <q-item v-for="upload in publishedUploads.slice(uploadsIndex, uploadsIndex + 4)" :key="upload.uid" :href="upload.path" target="_blank" class="row items-center">
            <q-item-section avatar>
              <q-icon :name="getIcon(upload)" />
            </q-item-section>
            <q-item-section>
              {{ upload.name }}
            </q-item-section>
            <q-item-section avatar right>
              <q-btn flat tag="a" @click="downloadFile(upload.path, upload.name)" target="_blank">
                <q-icon name="get_app" size="18px"/>
              </q-btn>
            </q-item-section>
          </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
import { date } from 'quasar'
export default {
  data () {
    return {
      uploadsIndex: 0
    }
  },
  computed: {
    uploads: function () {
      return this.$store.getters['uploads/getByAssignedUid'](this.$store.getters['auth/filterBy'])
    },
    publishedUploads: function () {
      let today = new Date()
      return this.uploads.filter(upload => {
        let publishDiff = date.getDateDiff(new Date(upload.publish_at), today, 'seconds')
        let expirationDiff = date.getDateDiff(new Date(upload.expires_at), today, 'seconds')
        return (publishDiff < 0) && (expirationDiff > 0 || upload.expires_at === null)
      })
    }
  },
  methods: {
    downloadFile (filePath, fileName) {
      window.open(filePath)
    },
    getIcon (file) {
      let icon
      switch (file.type) {
        case 'mp4':
        case 'qt':
        case 'mov':
          icon = 'ondemand_video'
          break
        case 'pdf':
          icon = 'picture_as_pdf'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
          icon = 'image'
          break
        default:
          icon = 'insert_drive_file'
          break
      }
      return icon
    }
  }
}
</script>
