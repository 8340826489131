<template>
  <q-card flat class="bg-lmsBackground">
    <q-card-section class="row justify-between items-center bg-lmsSidebar" id="announcementSection">
      <div class="text-h4">Announcements</div>
      <div class="row justify-end">
        <q-btn :disable="publishedAnnouncements[announcementsIndex - 4] ? false : true" flat round icon="keyboard_arrow_left" @click="announcementsIndex -= 4" />
        <q-btn :disable="publishedAnnouncements[announcementsIndex + 4] ? false : true" flat round icon="keyboard_arrow_right" @click="announcementsIndex += 4" />
      </div>
    </q-card-section>
    <!-- <q-separator inset/> -->
    <q-card-section>
      <q-list>
        <q-item v-for="announcement in publishedAnnouncements.slice(announcementsIndex, announcementsIndex + 4)" :key="announcement.uid">
          <q-item-section avatar>
            <q-icon name="fiber_manual_record" size="10px"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ announcement.title }}</q-item-label>
            <q-item-label caption class="secondaryText-text">{{ announcement.body }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
import { date } from 'quasar'
export default {
  data () {
    return {
      announcementsIndex: 0
    }
  },
  computed: {
    announcements: function () {
      return this.$store.getters['announcements/getByAssignedUid'](this.$store.getters['auth/filterBy'])
    },
    publishedAnnouncements: function () {
      let today = new Date()
      return this.announcements.filter(ann => {
        let diff = date.getDateDiff(new Date(ann.publish_at), today, 'seconds')
        return diff < 0
      })
    }
  }
}
</script>
