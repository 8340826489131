<template>
  <div class="row full-width">

    <div v-if="announcements.length > 0" class="col-xs-12 col-md-6">
     <announcements-section :announcements="announcements" />
    </div>

    <div v-if="uploads.length > 0" class="col-xs-12 col-md-6">
      <uploads-section :uploads="uploads" />
    </div>

    <div v-if="links.length > 0" :class="linksClass">
      <links-section :links="links" />
    </div>

  </div>
</template>

<script>
import AnnouncementsSection from 'components/homepage/announcementsSection'
import UploadsSection from 'components/homepage/uploadsSection'
import LinksSection from 'components/homepage/linksSection'
import { date } from 'quasar'

export default {
  components: { AnnouncementsSection, UploadsSection, LinksSection },
  computed: {
    announcements: function () {
      let allAnnouncements = this.$store.getters['announcements/getByAssignedUid'](this.$store.getters['auth/filterBy'])
      let today = new Date()
      return allAnnouncements.filter(ann => {
        let diff = date.getDateDiff(new Date(ann.publish_at), today, 'seconds')
        return diff < 0
      })
    },
    uploads: function () {
      let allUploads = this.$store.getters['uploads/getByAssignedUid'](this.$store.getters['auth/filterBy'])
      let today = new Date()
      return allUploads.filter(upload => {
        let publishDiff = date.getDateDiff(new Date(upload.publish_at), today, 'seconds')
        let expirationDiff = date.getDateDiff(new Date(upload.expires_at), today, 'seconds')
        return (publishDiff < 0) && (expirationDiff > 0 || upload.expires_at === null)
      })
    },
    links: function () {
      return this.$store.getters['links/getByAssignedUid'](this.$store.getters['auth/filterBy'])
    },
    linksClass () {
      if (this.announcements.length < 1 || this.uploads.length < 1) {
        return 'col-xs-12 col-md-6'
      } else {
        return 'col-12 q-pa-sm'
      }
    }
  }
}
</script>
