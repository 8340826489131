<template>
  <q-card flat class="bg-lmsBackground">
    <q-card-section class="row justify-between items-center bg-lmsSidebar" id="newsSection">
      <div class="text-h4">
        News
      </div>
      <div class="row justify-end">
          <q-btn :disable="publishedNews[newsIndex - 1] ? false : true" flat round icon="keyboard_arrow_left" @click="newsIndex--">
            <q-tooltip v-if="publishedNews[newsIndex - 1] ? true : false">Previous Article</q-tooltip>
          </q-btn>
          <q-btn :disable="publishedNews[newsIndex + 1]? false : true" flat round icon="keyboard_arrow_right" @click="newsIndex++">
            <q-tooltip v-if="publishedNews[newsIndex + 1] ? true : false">Next Article</q-tooltip>
          </q-btn>
        </div>
    </q-card-section>
    <!-- <q-separator inset/> -->
    <q-card-section>
      <q-card :key="publishedNews[newsIndex].uid" flat class="bg-lmsBackground">
        <q-card-section class="newsContent" v-html="publishedNews[newsIndex].body" />
      </q-card>
    </q-card-section>
  </q-card>
</template>

<script>
import { date } from 'quasar'
export default {
  props: ['newsItems'],
  data () {
    return {
      newsIndex: 0
    }
  },
  computed: {
    news: function () {
      return this.$store.getters['news/getByAssignedUid'](this.newsItems, this.$store.getters['auth/filterBy'])
    },
    publishedNews: function () {
      let today = new Date()
      return this.news.filter(news => {
        let publishDiff = date.getDateDiff(new Date(news.publish_at), today, 'seconds')
        let expirationDiff = date.getDateDiff(new Date(news.expires_at), today, 'seconds')
        return (publishDiff < 0) && (expirationDiff > 0 || news.expires_at === null)
      })
    }
  },
  methods: {
    getDate (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    }
  }
}
</script>

<style lang="stylus">
.newsContent * img
      max-width: 100% !important
</style>
